require('./bootstrap');
require('@fortawesome/fontawesome-free/js/all');
require('bootstrap-select/dist/js/bootstrap-select.min.js');
require('intl-tel-input/build/js/intlTelInput-jquery.min.js');
require('intl-tel-input/build/js/utils.js');
require('bootstrap-datepicker/js/bootstrap-datepicker.js');
require('jquery-match-height/dist/jquery.matchHeight-min.js');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');
require('jquery-confirm/dist/jquery-confirm.min.js');
require('imagesloaded/imagesloaded.pkgd.min.js');
require('slick-slider/slick/slick.min.js');
